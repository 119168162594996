
try {
    var hidden;
    var visibilityChange;

    if (typeof document.hidden !== "undefined") {
        hidden = "hidden";
        visibilityChange = "visibilitychange";
    } else if (typeof document.mozHidden !== "undefined") {
        hidden = "mozHidden";
        visibilityChange = "mozvisibilitychange";
    }
    else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
    }
    else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
    else if (typeof document.oHidden !== "undefined") {
        hidden = "oHidden";
    }
}
catch (e) {
    console.log(e);
}

var body = document.getElementsByTagName("BODY")[0];
body.setAttribute('data-totalViewTime', 0);

var tracking_elements = [];

var element_tracking = document.querySelectorAll('.element-tracking');
element_tracking.forEach(function (element) {
    element.setAttribute('data-totalViewTime', 0);
    element.setAttribute('element-meta', element.id);
    tracking_elements.push(element);
})

var visibleElements = new Set();
var previouslyVisibleElements = new Set();

var observerOptions = {
    root: null,
    rootMargin: "0px",
    treshold: 0.25
}

var observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
        var element = entry.target;

        if (entry.isIntersecting) {
            visibleElements.add(element);
        } else {
            visibleElements.delete(element);
        }
    });
}, observerOptions);

tracking_elements.forEach(function (element) {
    observer.observe(element);
});

document.addEventListener(visibilityChange, function () {

    if (document[hidden] === true) {
        previouslyVisibleElements = visibleElements;
        visibleElements = new Set();
    } else {
        visibleElements = previouslyVisibleElements;
        previouslyVisibleElements = new Set();
    }

});

window.setInterval(function () {

    if (document[hidden] === false) {
        body.setAttribute("data-totalViewTime",
            parseFloat(body.getAttribute("data-totalViewTime")) + 1
        );
    }

    visibleElements.forEach(function (element) {
        if (document[hidden] === false) {
            element.setAttribute(
                "data-totalViewTime",
                parseFloat(element.getAttribute("data-totalViewTime")) + 1
            );
        }
    });

}, 1000);

window.addEventListener('beforeunload', function (event) {
    tracking_elements.forEach(function (element) {

        if (parseFloat(element.getAttribute("data-totalViewTime")) > 0) {
            dataLayer.push({
                'event': 'gtm.element.visibility.event',
                'elementMeta': element.getAttribute('element-meta'),
                'elementTimeTotal': parseFloat(element.getAttribute("data-totalViewTime"))
            });
        }
    })

    dataLayer.push({
        'event': 'gtm.page.visibility.event',
        'pageMeta': window.location.href,
        'pageTimeTotal': parseFloat(body.getAttribute("data-totalViewTime"))
    });
}, false);